import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Hero from "common/hero";
import Seo from "common/seo";
import CategoryDetails from "common/category-details";
import { TypeA, TypeC } from "common/callouts";
import useDeviceSize from "libs/hooks/useDeviceSize";

import heroImageRef from "images/baby/general-care/baby-generalcare-hero.webp";
import productInfoIcon from "images/icons/icon-general-care.png";
import productInfoImg from "images/baby/general-care/general-care-data-visual.webp";
import img1 from "images/baby/general-care/general-care-baby-healing-ointment.webp";
import img1Mobile from "images/baby/general-care/general-care-baby-healing-ointment-mobile.webp";
import img2 from "images/baby/general-care/general-care-babywash-shampoo.webp";
import img3 from "images/baby/general-care/general-care-itchrelief-ointment.webp";
import img4 from "images/baby/general-care/section-cout-advanced-repair-features.webp";
import img5 from "images/baby/general-care/Eczema_Regimen-Callout_Footer.webp";
import img6 from "images/baby/general-care/Diaper-Rash-Callout_Footer.webp";
import img7 from "images/baby/general-care/Baby-Resources-Callout_Footer.webp";

import "./generalcare.scss";

const MyHero = () => <Hero className="inner-hero" bgImagePath={heroImageRef} />;

function GeneralCarePage({ pageContext }) {
  const deviceSizes = useDeviceSize();
  return (
    <Layout
      pageClass="page-baby-generalcare"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo
        pageTitle="Baby General Care"
        pageDescription="Learn about Eucerin and Aquaphor general care for babies' skin"
      />
      <div className="inner-body-content">
        <section className="inner-centered-container">
          <CategoryDetails
            headBgColor="#5daee1"
            headText="GENERAL CARE"
            infoText="Discover the versatility of our baby skin care solutions"
            bodySpanText=""
            bodyText="Turn bathing and moisturizing into <span class='font-semi-bold'>bonding and making memories</span>."
            productInfoIcon={productInfoIcon}
            productInfoIconAlt="Icon for General Care"
            productInfoImg={productInfoImg}
            productInfoImgAlt="Aquaphor Baby Wash and Shampoo Image"
          >
            <h5 className="picton-blue">
              Recommend Aquaphor<sup>&reg;</sup> Baby products as part of the
              daily routine
            </h5>
            <p>
              From targeted treatment of dry, irritated skin to a cleanser
              that’s clinically proven and gentle, help parents and caregivers
              provide everyday skin care for their little ones... and more.
            </p>
            <ul className="picton-bullets">
              <li>
                <span>
                  <span className="font-semi-bold">
                    Uniquely formulated, multipurpose
                  </span>{" "}
                  ointment that can be used for many baby skin care needs, from
                  chapped cheeks to minor scrapes and scratches, to diaper rash,
                  to dry, irritated skin
                </span>
              </li>
              <li>
                <span>
                  <span className="font-semi-bold">Clinically proven</span>{" "}
                  cleanser gently washes babies’ sensitive skin, hair, and scalp
                </span>
              </li>
              <li>
                <span>
                  Both products are{" "}
                  <span className="font-semi-bold">effective yet mild</span>{" "}
                  enough for everyday use to help encourage bonding time
                </span>
              </li>
            </ul>
          </CategoryDetails>
        </section>
        <section className="callouts-container gradient-bg gray-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} lg={4} order={{ xs: 2, lg: 1 }}>
                <TypeC
                  head1={`Aquaphor<sup>&reg;</sup> Baby Healing Ointment`}
                  head1Css="picton-blue"
                  buttonText="See product information"
                  buttonUrl="/baby/generalcare/aquaphorbabyhealingointment"
                  className="center-v"
                >
                  Ideal for irritated skin, diaper and drool rash, and minor
                  scratches and scrapes
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 1, lg: 2 }}>
                {deviceSizes?.smUp ? <img
                  src={img1}
                  alt="Aquaphor Baby Healing Ointment"
                  className="cout-product-img"
                />
                : <img
                  src={img1Mobile}
                  alt="Aquaphor Baby Healing Ointment"
                  className="cout-product-img mobile"
                />}

              </Col>
              <Col sm={12}  md={8} lg={4} order={{ xs: 4, lg: 3 }}>
                <TypeC
                  head1={`Aquaphor<sup>&reg;</sup> Baby Wash & Shampoo`}
                  head1Css="picton-blue"
                  buttonText="See product information"
                  buttonUrl="/baby/generalcare/aquaphorbabywashshampoo"
                  className="center-v"
                >
                  Gently soothes and cleanses babies’ skin and hair without
                  drying
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 3, lg: 4 }}>
                <img
                  src={img2}
                  alt="Aquaphor Baby Wash & Shampoo"
                  className="cout-product-img"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={8} lg={4} order={{ xs: 2, lg: 1 }}>
                <TypeC
                  head1={`Aquaphor<sup>&reg;</sup> Children’s Itch Relief Ointment`}
                  head1Css="picton-blue"
                  buttonText="See product information"
                  buttonUrl="/baby/generalcare/aquaphorchildrensitchreliefointment"
                  className="center-v"
                >
                  For immediate and long-lasting itch relief from eczema flares
                  and skin irritations
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 1, lg: 2 }}>
                <img
                  src={img3}
                  alt="Aquaphor Children's Itch Relief Ointment"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>
        <section className="callouts-container gradient-bg section-daily-moisturization">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={12} lg={6} order={{ xs: 2, lg: 1 }} className="">
                <TypeC
                  prodLabel={`FOR DAILY MOISTURIZATION`}
                  prodLabelCss="h3-boxed northern-lights-blue"
                  buttonText="SEE EUCERIN<sup>®</sup> ADVANCED REPAIR"
                  buttonUrl="/body/dryskin"
                  className="center-v"
                >
                  <p className="reg-text-big reg-text-big-only">
                    A complete therapeutic approach to moisturizing skin
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={12} lg={6} order={{ xs: 1, lg: 2 }} className="">
                <img
                  src={img4}
                  alt="Eucerin Adavanced Repair Cream"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img5}
                  imgAlt="Eczema Regimen"
                  caption="Choose a proactive regimen for eczema-prone skin"
                  buttonText="Eczema Regimen"
                  buttonUrl="/baby/eczema"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img6}
                  imgAlt="Diaper Rash Relief"
                  caption="Recommended for relief <br className='show-desktop' /> of mild to severe diaper rash"
                  buttonText="Diaper Rash Relief"
                  buttonUrl="/baby/diaperrash"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img7}
                  imgAlt="Browse Resources"
                  caption="Find resources to support your baby care recommendations"
                  buttonText="Browse Resources"
                  buttonUrl="/resources"
                  imgStyles={{ height: 125 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default GeneralCarePage;
